import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils'; 

const apiUrl = process.env.REACT_APP_API_URL; 
const user = getSessionData('user'); 
const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    // Fetch the email campaign data when the component mounts
    const fetchCampaigns = async () => {
      const endpoint = `list-email-campaigns?user_id=${user.id}`; 
      try {
        const response = await fetch(`${apiUrl}/api/${endpoint}`);
        if (!response.ok) {
          throw new Error('Network response was not ok'); 
        }
        const data = await response.json();
        setCampaigns(data); 
      } catch (error) {
        console.error('Error fetching email campaigns:', error);
      } finally {
        setLoading(false); 
      }
    };

    if (user && user.id) { 
      fetchCampaigns();
    } else {
      setLoading(false); // Turn off loading indicator
    }
  }, []); 

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <div className="email_listing_mainsec">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card-body">
            <div className="table_mainsec">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Email</th>
                      <th>SMTP Username</th>
                      <th>Password</th>
                      <th>SMTP Host</th>
                      <th>Port</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.length > 0 ? (
                      campaigns.map((campaign, index) => (
                        <tr key={index}>
                          <td>{campaign.id}</td>
                          <td>{campaign.email}</td>
                          <td>{campaign.smtp_username}</td>
                          <td>{campaign.password}</td>
                          <td>{campaign.smtp_host}</td>
                          <td>{campaign.port}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No campaigns found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
