import { useState } from 'react';
import Papa from 'papaparse';
import { Navigate } from 'react-router-dom';
import { getSessionData } from './utils';

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl1 = 'https://myemail-verifier.com:3000';
function Emailchecker() {
  const user = getSessionData('user');
  const [message, setMessage] = useState(false);
  const [message1, setMessage1] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [emaildata, setEmaildata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState(null);
  const [results, setResults] = useState([]);
  const [results1, setResults1] = useState([]);
  const [email, setEmail] = useState('');
  const [email1, setEmail1] = useState(false);
  const [validemail, setValidemail] = useState(0);
  const [invalidemail, setInvalidemail] = useState(0);
  const [totalemail, setTotalemail] = useState(0);

  const handleFileChange = (event) => {
    setFilename(event.target.files[0].name);
    setCsvFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (csvFile) {
      const reader = new FileReader();

      reader.onload = async function (e) {
        const csvData = e.target.result;
        await sendCSVData(csvData);
      };

      reader.readAsText(csvFile);
    } else {
      alert('Please select a CSV file');
    }
  };

  const sendRequest = async (rk) => {
    const endpoint = 'emailchecker';
    const response = await fetch(`${apiUrl1}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: rk }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data1 = await response.json();
    return data1;
  };

  const sendCSVData = async (csvData) => {
    const timer1 = setTimeout(() => {
      setMessage1('Still at It!');
      setMessage('I’m still crunching those numbers. Thanks for hanging in there!');
    }, 15 * 60 * 1000);

    const timer2 = setTimeout(() => {
      setMessage1('Almost There!');
      setMessage('You’re doing great! Just a little more time.');
    }, 30 * 60 * 1000);

    const timer3 = setTimeout(() => {
      setMessage1('Final Stretch!');
      setMessage('We’re in the home stretch now!');
    }, 45 * 60 * 1000);

    Papa.parse(csvData, {
      header: false,
      skipEmptyLines: true,
      complete: async (results) => {
        const rows = results.data;
        save_json_file(rows);
        setEmaildata([]);
        setResults([]);
        setLoading(true);
        let v_email = 0;
        let in_email = 0;

        for (let i = 1; i < rows.length; i++) {
          if (rows[i].length > 0) {
            try {
              const rk = rows[i];
              if (rk[0] !== '') {
                const result = await sendRequest(rk);
                if (result.results.length > 0 && result.results[0].status) {
                  v_email += 1;
                  setResults((prevResults) => [
                    ...prevResults,
                    {
                      WarpLeadsId: rk[0],
                      FirstName: rk[1],
                      LastName: rk[2],
                      JobTitle: rk[3],
                      PersonLinkedInUrl: rk[4],
                      PersonCountry: rk[5],
                      CompanyName: rk[6],
                      CompanyIndustry: rk[7],
                      CompanySize: rk[8],
                      CompanyWebsite: rk[9],
                      CompanyCountry: rk[10],
                      CompanyCity: rk[11],
                      WorkEmail: rk[12],
                      PersonalEmail: rk[13],
                      Phone1: rk[14],
                      Phone2: rk[15],
                      Status: 'valid',
                    },
                  ]);
                } else {
                  in_email += 1;
                  setResults1((prevResults) => [
                    ...prevResults,
                    {
                      WarpLeadsId: rk[0],
                      FirstName: rk[1],
                      LastName: rk[2],
                      JobTitle: rk[3],
                      PersonLinkedInUrl: rk[4],
                      PersonCountry: rk[5],
                      CompanyName: rk[6],
                      CompanyIndustry: rk[7],
                      CompanySize: rk[8],
                      CompanyWebsite: rk[9],
                      CompanyCountry: rk[10],
                      CompanyCity: rk[11],
                      WorkEmail: rk[12],
                      PersonalEmail: rk[13],
                      Phone1: rk[14],
                      Phone2: rk[15],
                      Status: 'invalid',
                    },
                  ]);
                }
              }
            } catch (error) {
              console.error('Error processing row:', i, error);
            }
          }
        }
        setValidemail(true);
        setInvalidemail(in_email);
        setTotalemail(v_email + in_email);
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
        setMessage(false);
        setMessage1(false);
        setLoading(false);
      },
    });
  };

  const save_json_file = async (rows) => {
    // setValidemail(false);
    try {
      const time = Date.now()
      const endpoint = 'save-json';
      const payload = {
        email: user.email,
        userid: user.id,
        rows: rows,
        status: 0,
        time:time
      };

      console.log('Sending payload to API:', payload);

      const response = await fetch(`${apiUrl1}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Server error:', errorDetails);
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('API Response:', data);
      setLoading(false);
      setValidemail(true);
      setFilename(null);
    } catch (error) {
      console.error('Fetch Error:', error.message);
    }
  };

  const downloadCSV = () => {
    const edata = results;
    if (edata.length > 0) {
      const headers = Object.keys(edata[0]).join(',') + '\n';
      const rows = edata
        .map((item) =>
          Object.values(item)
            .map((value) => {
              if (typeof value === 'string') {
                value = value.replace(/"/g, '""');
                if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                  value = `"${value}"`;
                }
              }
              return value;
            })
            .join(',')
        )
        .join('\n');
      const csvContent = headers + rows;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute('download', 'valid-emails.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No data available to export.');
    }
  };

  const downloadCSV1 = () => {
    const edata1 = results1;
    if (edata1.length > 0) {
      const headers = Object.keys(edata1[0]).join(',') + '\n';
      const rows = edata1
        .map((item) =>
          Object.values(item)
            .map((value) => {
              if (typeof value === 'string') {
                value = value.replace(/"/g, '""');
                if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                  value = `"${value}"`;
                }
              }
              return value;
            })
            .join(',')
        )
        .join('\n');
      const csvContent = headers + rows;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute('download', 'invalid-emails.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No data available to export.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail1(false);
    const endpoint = 'singleemailchecker';
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl1}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setLoading(false);
      const data = await response.json();
      setEmail1(data.results[0]);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleClosePopup = () => {
    setValidemail(0);
    setInvalidemail(0);
  };

  return (
    <div class="emailchecker_mainsec">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="fileinput_sec">
      
              <div className="upload_box">
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  required
                  className="form-control"
                />
                <div className="upload_txt">
                  <i className="fas fa-upload"></i>
                  <div className="contnt_txt">
                    {filename ? <p>{filename}</p> : <p>Choose a file or drag it here.</p>}
                  </div>
                </div>
              </div>
              <div className="btn_mainsec">
                <button className="btn_style" onClick={handleUpload}>Upload CSV</button>
                <a className="btn_style download_s_btn" href="https://staging.automate-mailscale.io/Email-erfication-Tool-Template.csv" download>Download Sample File</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loader_mainsec">
      <div className="loader_innerbox">
        <div className="spinner_box">
          <div className="spinner"></div>
        </div>
        { message ? (
          <div className="loader_contnt">
            <p>Please wait.....</p>
            { message1 ? <p> {message1} </p> : null }
            { message ? <p> {message} </p> : null }
          </div> 
          ) : (
          <div className="loader_contnt">
            <p>Please wait.....</p>
            <p>Hold Tight – We’re Scanning Your File!</p>
            <p>Please don’t close this window – we're carefully processing your list and making sure everything is just right.</p>
            <br/>
          </div> 
        )}
      </div>
        </div>
      )}

      {validemail && (
        <div>
          <div className="modal fade infopopupModal in show" id="infopopupModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{display:'block'}}>
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
              <div className="closebtn">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}></button>
              </div>
               
              <div className="contnt_mainsec">
                <div className="total_emailbox">
                <h4>Thank You – We’ve Received Your File!</h4>
                <p>Your file has been successfully submitted, and we’ll send you an email to your verified address once the process is complete in approx 15minutes.</p>
                <p>Thanks for your patience!</p>
                </div>
              </div>
              </div>
              
              <div className="modal-footer">
                <div className="btn_mainsec">
                  
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        )}
    </div>
  );
}

export default Emailchecker;
