import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import { getSessionData } from './utils';
//const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;

const AddEmail = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null); // Reference for the file input

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      setFileName(selectedFile.name);
    } else {
      setFileName('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please upload a CSV file.");
      return;
    }

    setLoading(true);

    // Parse the CSV file
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
        const userId = getSessionData('user')?.id; 
        // Log the parsed data for debugging
       // console.log("Parsed data:", data);
       if (!userId) {
          alert('User ID is missing');
          setLoading(false);
          return;
        }
       // console.log("User ID:", userId); 
        const endpoint = 'email-campaign';
        try {
          const response = await fetch(`${apiUrl}/api/${endpoint}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify({ data, user_id: userId }),

          });

          if (response.ok) {
            alert("CSV data uploaded successfully!");
          } else {
            // Assuming the server responds with a JSON object containing the error message
            const errorData = await response.json();
            if (errorData.error) {
              alert(`Error: ${errorData.error}`);
            } else {
              alert("Error uploading data.");
            }
          }
        } catch (error) {
          console.error("Error:", error);
          alert("Error uploading data.");
        } finally {
          // Reset all relevant states after completion
          setFile(null);
          setFileName('');
          setLoading(false);
          fileInputRef.current.value = ''; // Reset the input field
        }
      },
    });
  };

  return (
    <div className="addnew_emails_mainsec">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="csv_upload_boxsec">
            <form onSubmit={handleSubmit}>
              <div className="upload_box">
                <input 
                  type="file" 
                  className="form-control" 
                  onChange={handleFileChange} 
                  accept=".csv" 
                  ref={fileInputRef} // Attach the ref to the input
                />
                <div className="upload_txt">
                  <i className="fas fa-upload"></i>
                  <div className="contnt_txt">
                    <p>Choose a file or drag it here.</p>
                  </div>
                </div>
              </div>

              {fileName && (
                <div className="uploaded-file-name">
                  <p>Uploaded File: {fileName}</p>
                </div>
              )}

              {loading && (
                <div className="loader">
                  <p>Loading...</p> {/* Replace with your loader component */}
                </div>
              )}

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="btn_mainsec">
                  <button type="submit" className="btn_style" disabled={loading}>
                    {loading ? "Uploading..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
         {loading ? <div className="loader_mainsec">
            <div className="spinner"></div>
          </div> : <div></div>}
      </div>

    </div>
  );
};

export default AddEmail;
