import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { saveSessionData, getSessionData } from './utils';
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const user = getSessionData('user');
  if(user) {
    return <Navigate to="/" replace />;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = 'login';
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      setLoading(false);
      const data = await response.json();      
      if (data.status) {
        saveSessionData('user', data.user);
        window.location.href = homeUrl+'/dashboard';
      } 
      else {
        alert(data.message);
      }
    } 
    catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="login_mainsec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="login_boxsec">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="title_mainsec">
                        <h1>Login</h1>
                      </div>
                    </div>
                  </div>
                    
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="group-form">
                        <label className="form-label">Email address</label>
                        <input  
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Email address" 
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="group-form">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          className="form-control" 
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="group-form form-check">
                        <input type="checkbox" className="form-check-input" id="remember_check" />
                        <label className="form-check-label" for="remember_check">Remember me</label>
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="forgot_pass_txt">
                         <a href={`${homeUrl}/forgot-password`}>Forgot password?</a>
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="btn_mainsec">
                        <button type="submit" className="btn_style">Submit</button>
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="signup_link">
                        <span>Don't have an account? <a href={`${homeUrl}/signup`}>Register</a></span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {loading ? <div className="loader_mainsec">
            <div className="spinner"></div>
          </div> : <div></div>}
      </div>
    </div>
  );
};
export default Login;