// src/components/Layout.js
import React from 'react';
import '../assets/css/layout.css'; 
import { useLocation } from 'react-router-dom';
import TopBar from './TopHead'; 
import SideBar from './Sidebar'; 
import Dashboard from './Dashboard';
import Campaigns from './Campaigns'; 
import Analytics from './Analytics';
import AddEmail from './AddEmail';
import Emailchecker from './Emailchecker';
import Searchdomain from './Searchdomain';
// import Emailchecker from './Emailchecker';

const Layout = () => {
    const location = useLocation(); // Get the current location

    const renderContent = () => {
        switch (location.pathname) {
            case '/campaigns':
                return <Campaigns />;
            case '/analytics':
                return <Analytics />;
            case '/add-email':
                return <AddEmail />;
            case '/emailchecker':
                return <Emailchecker />;    
            case '/search-domain':
                return <Searchdomain />;
            case '/':
                return <Dashboard />;
            default:
                return <Dashboard />;
        }
    };

    return (
        <div className="dashboard_mainbox">
        <SideBar />
             // {(location.pathname !== '/search-domain') && <SideBar />} {/* Render SideBar on */}
            <div className="dashboard_body">
                <TopBar /> {/* TopBar component */}
                {renderContent()} {/* Conditionally render content based on the current route */}
            </div>
        </div>
    );
};

export default Layout;