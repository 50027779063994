import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import validator from 'validator'
import { getSessionData } from './utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardForm from './CardForm';
const stripePromise = loadStripe('pk_test_mWl2KTmJNDdUf3DndeSdiMYV00QopYoqHy');
const apiUrl = process.env.REACT_APP_API_URL;

const Searchdomain = () => {
	const user = getSessionData('user');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [domaindata, setDomaindata] = useState([]);
	const [tamout, setTamout] = useState(0);
	const [checkedAmounts, setCheckedAmounts] = useState([0,0,0,0]);
	const [checkedDomains, setCheckedDomains] = useState(['','','','']);
	const [view_start_again, setViewstartagain] = useState(0);
	const [step, setStep] = useState(1);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [email_accounts, setEmailaccounts] = useState('');
	const [domains, setDomains] = useState('');
	const [suggest_domains, Setsuggestdomains] = useState('');
	const [domain_name, setDomainname] = useState('');
	const [email_name, setEmailname] = useState('');
	const [email_username, setEmailusername] = useState('');
	const [email_usernames, setEmailUsernames] = useState([]);
	const [forward_domains, setForwarddomains] = useState('');

	if(!user) {
		return <Navigate to="/login" replace />;
	}
	const nextStep = () => {
    if (step !== 8) {
        console.log(step);
        
        if (step === 2 && fname === '') {
            setError('Oops! Please enter first name');
        } else if (step === 2 && lname === '') {
            setError('Oops! Please enter last name');
        } else if (step === 2 && email === '') {
            setError('Oops! Please enter email');
        } else if (step === 3 && domains === '') {
            setError('Oops! Please enter a value');
        } else if (step === 3 && (domains < 1 || domains > 5)) {
            setError('Please enter a number between 1 and 5');
        } else if (step === 4 && suggest_domains === '') {
            setError('Oops! Please make a selection');
        } else if (step === 5 && domain_name === '') {
            setError('Please fill this in');
        } else if (step === 6 && email_name === '') {
            setError('Please fill this in');
        } else if (step === 7) {
		    // Validation for usernames
		    if (email_username === '') {
		        setError('Please fill this in');
		    } else {
		        // Split the entered usernames into an array
		        const usernamesArray = email_username.split(/[\s,]+/).map(u => u.trim()).filter(Boolean);
		        
		        // Check if the number of usernames matches the specified number from step 3
		        if (usernamesArray.length !== parseInt(domains, 10)) {
		            setError(`Please enter exactly ${domains} usernames.`);
		        } 
		        // Check if all usernames end with '@'
		        else if (usernamesArray.some(username => !username.endsWith('@'))) {
		            setError('Each username must end with "@" symbol (e.g., admin@, info@, etc.)');
		        } 
		        else {
		            setError(''); // Clear error if valid
		            setStep(step + 1); // Move to next step
		        }
		    }
		} else if (step === 8 && forward_domains === '') {
            setError('Please fill this in');
        } else {
            setError(''); // Clear any previous error
            setStep(step + 1); // Move to the next step
	        }
	    }
	};

	const prevStep = () => {
	    if (step !== 1) {
	        setStep(step - 1);
	        setError(''); // Clear the error when going back
	    }
	};


	const start_again = () => {
		setStep(7);
		setTamout(0);
		setDomaindata([]);
		setViewstartagain(0);
	};

	// const handleCheckboxChange = (e) => {
	//     const { checked } = e.target;
	//     const value = e.target.dataset.value;
	//     const domain = e.target.dataset.domain;
	//     const ind = e.target.dataset.ind;
	//     const numericValue = parseFloat(value);

	//     if(checked) {
	//     	checkedAmounts[ind] = value;
	//     	checkedDomains[ind] = domain;
	//     }
	//     else {
	//     	checkedAmounts[ind] = 0;
	//     	checkedDomains[ind] = '';
	//     }
	//     setCheckedAmounts(checkedAmounts);
	//     setCheckedDomains(checkedDomains);
	//     setTamout((prevTamout) => {
	//         return checked ? prevTamout + numericValue : prevTamout - numericValue;
	//     });
	// };
	const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        const value = e.target.dataset.value;
        const domain = e.target.dataset.domain;
        const ind = e.target.dataset.ind;
        const numericValue = parseFloat(value);

        // Update checked amounts and domains based on checkbox state
        if (checked) {
            checkedAmounts[ind] = value;
            checkedDomains[ind] = domain;
        } else {
            checkedAmounts[ind] = 0;
            checkedDomains[ind] = '';
        }
        setCheckedAmounts([...checkedAmounts]);
        setCheckedDomains([...checkedDomains]);

        // Update the total amount based on checkbox selection
        setTamout((prevTamout) => {
            return checked ? prevTamout + numericValue : prevTamout - numericValue;
        });
    };


	const handleSubmit = async (e) => {
	    e.preventDefault();
	    console.log('ok');
	    const endpoint = 'domains/check';
	    if(step === 8 && forward_domains === '') {
			setError('Please fill this in');
		}
		else {
			setLoading(true);
		    try {
		      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
		        },
		        body: JSON.stringify({ 
		        	fname,
		        	lname,
		        	email,
		        	// email_accounts,
		        	domains,
		        	suggest_domains,
		        	domain_name,
		        	email_name,
		        	email_username,
		        	forward_domains,
		        	'result': domain_name,
		        	'phone': '+1.5555555555',
		        	'address': '510 Townsend St',
			      	'postal_code': '98140',
			      	'city': 'San Francisco',
			      	'state': 'CA',
			      	'country': 'US'
		        }),
		      });
		      setLoading(false);
		      setViewstartagain(1);
		      setStep(0);
					const data = await response.json();    
					if (data.original) {
				  		setDomaindata(data.original)
				  } 
				  else {
				  	 	alert(data.message);
				  }
				  
		    } 
		    catch (error) {
		      	console.error(error);
		      	setLoading(false);
		    }
	  	}
  	};

	const handlePayment = async (paymentMethodId) => {
		setLoading(true);
	  	const response = await fetch(apiUrl+'/api/create-payment-intent', {
	    method: 'POST',
	    headers: {
	      'Content-Type': 'application/json'
	    },
	    body: JSON.stringify({
			amount: tamout*100,
			paymentMethodId,
			"name": fname+' '+lname,
			user_id: user.id,
			user_profile: user.name,
			fname,
	      	lname,
	      	email,
	      	// email_accounts,
	      	domains,
	      	suggest_domains,
	      	domain_name,
	      	email_name,
	      	email_username,
	      	forward_domains,
	      	'allamout': checkedAmounts,
	      	'result': checkedDomains,
	      	'phone': '+1.5555555555',
	      	'address': '510 Townsend St',
	      	'postal_code': '98140',
	      	'city': 'San Francisco',
	      	'state': 'CA',
	      	'country': 'US'
	    })
	  });
		
	  const paymentResult = await response.json();
	  setLoading(false);
	  alert('Payment successful');
	  window.location.reload();
	  
  };
	return (
		<div className="step_questions_form">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<div className="form_mainsec">
							<form onSubmit={handleSubmit}>
								<div className="step_form">
									<div className="form_sec step01" style={{ display: step === 1 ? 'block' : 'none' }}>
										<div className="intro_contnt">
											<div className="title_sec">
												<h1>Welcome to Automation Tool!</h1>
											</div>
											
											<div className="btn_mainsec">
												<a className="btn_style" href="javascript:void(0);" onClick={nextStep}>I'm Ready</a>
												<span>Press Enter ↵</span>
												<p><i className="fas fa-clock"></i> Takes 3 minutes</p>
											</div>
										</div>
									</div>
									
									<div className="form_sec step02" style={{ display: step === 2 ? 'block' : 'none' }}>
										<div className="title_sec">
											<h2><span className="number_txt">1 <i className="fas fa-arrow-right"></i></span> Contact Information</h2>
											<p>So we can match your order to your profile and DM you the email accounts.</p>
										</div>
										
										<div className="inner_formsec">
											<div className="row">
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<label>First name</label>
														<input 
															type="text" 
															className="form-control" 
															placeholder="First name"  
															name="fname"
															value={fname}
															onChange={(e) => setFname(e.target.value)}
														/>
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<label>Last name</label>
														<input 
															type="text" 
															className="form-control" 
															placeholder="Last name"
															name="lname"
															value={lname}
															onChange={(e) => setLname(e.target.value)} 
														/>
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<label>Email</label>
														<input 
															type="email" 
															className="form-control" 
															placeholder="name@example.com"
															name="email"
															value={email}
															onChange={(e) => setEmail(e.target.value)}
														/>
														{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
													</div>
												</div>
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="btn_mainsec">
														<a href="javascript:void(0);" className="btn_style" onClick={nextStep}>OK</a>
														<span>Press Enter ↵</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									
									
									
									<div className="form_sec step03" style={{ display: step === 3 ? 'block' : 'none' }}>
										<div className="title_sec">
											<h2><span className="number_txt">2 <i className="fas fa-arrow-right"></i></span> How many emails do you want per domain?*</h2>
											<p>Your answer will determine how many domains to purchase.</p>
											<p>You can setup up to 3 emails per domain to reduce your domain costs.</p>
											<p>Even if you send emails with the same name, we can create variations.</p>
											<p>Example: yassin@staging.automate-mailscale.io, yassin.b@staging.automate-mailscale.io and yassinb@staging.automate-mailscale.io</p>
											<p>Please type the amount from 1-3 below.</p>
										</div>
										
										<div className="inner_formsec">
											<div className="row">
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<input 
															type="text" 
															className="form-control" 
															placeholder="Type your answer here..."
															name="domains"
															value={domains}
															onChange={(e) => setDomains(e.target.value)}
														/>
														{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="btn_mainsec">
														<a href="javascript:void(0);" className="btn_style" onClick={nextStep}>OK</a>
														<span>Press Enter ↵</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									
									<div className="form_sec step04" style={{ display: step === 4 ? 'block' : 'none' }}>
										<div className="title_sec">
											<h2><span className="number_txt">3 <i className="fas fa-arrow-right"></i></span> Do you want to suggest your own domains?</h2>
											<p>Yes 👉 You will provide a list of available domains in the next step</p>
											<p>No 👉 You will provide the company name and we will suggest similar sounding domainsThis question is required.*</p>
										</div>
										
										<div className="inner_formsec">
											<div className="row">
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<div className="custom_radio_btn">
															<label className="radio_custombox">
																<span className="label_txt"><span className="letter_style">Y</span> Yes</span>
																<input 
																	type="radio" 
																	name="suggest_domains"
																	value="Yes"
																	onChange={(e) => Setsuggestdomains(e.target.value)}
																/>
																<span className="checkmark"></span>
															</label>
														</div>
														
														<div className="custom_radio_btn">
															<label className="radio_custombox">
																<span className="label_txt"><span className="letter_style">N</span> No</span>
																<input 
																	type="radio" 
																	name="suggest_domains"
																	value="No"
																	onChange={(e) => Setsuggestdomains(e.target.value)}
																/>
																<span className="checkmark"></span>
															</label>
														</div>
														{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="btn_mainsec">
														<a href="javascript:void(0);" className="btn_style" onClick={nextStep}>OK</a>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="form_sec step05" style={{ display: step === 5 ? 'block' : 'none' }}>
										<div className="title_sec">
											<h2><span className="number_txt">4 <i className="fas fa-arrow-right"></i></span> Provide your domains belowThis question is required.*</h2>
											
											<p>To provide you with an automated setup, we currently only support:</p>
											<p>- Primary domain endings .com .co ($13 for a year)<br/>
											- Secondary domain ending .biz ($9 for a year).</p>
											<p>Deliverability wise, they perform the same (so you can save a bit with the secondary)</p>
											<p>Please suggest a list of domains formatted like the example below.</p>
											<p>ℹ️ Make sure to submit enough domains. If you want 50 email accounts with 2 emails per domain -> Then provide 25 domains</p>
											
											<p>Example answer:</p>
											<p>example1.com</p>
											<p>example2.co</p>
											<p>example3.co</p>
											<p>example4.biz...</p>
										</div>
										
										<div className="inner_formsec">
											<div className="row">
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<input 
															type="text" 
															className="form-control" 
															placeholder="Type your answer here..."
															name="domain_name"
															value={domain_name}
															onChange={(e) => setDomainname(e.target.value)}
														/>
														<span className="linebreak_txt">Shift ⇧ + Enter ↵ to make a line break</span>
														{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="btn_mainsec">
														<a href="javascript:void(0);" className="btn_style" onClick={nextStep}>OK</a>
														<span>Press Enter ↵</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="form_sec step06" style={{ display: step === 6 ? 'block' : 'none' }}>
										<div className="title_sec">
											<h2><span className="number_txt">5 <i className="fas fa-arrow-right"></i></span> What's the first and last name of the email accounts*</h2>
											<p>To provide you with a fast, automated setup, it helps to provide only one name.</p>
											<p>Providing multiple will delay the process as it requires manual matching.</p>
											<p>💡 Tip: Even if you have multiple people handling cold emails, we recommend to send from one person.</p>
											<p>When people reply to your emails, you can still let other team members reply.</p>
											<p>Most of our customers go either for the founder's name, employees name or a fantasy assistant name such as "Jessica David"</p>
										</div>
										
										<div className="inner_formsec">
											<div className="row">
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<input 
															type="text" 
															className="form-control" 
															placeholder="Type your answer here..."
															name="email_name"
															value={email_name}
															onChange={(e) => setEmailname(e.target.value)}
														/>
														{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="btn_mainsec">
														<a href="javascript:void(0);" className="btn_style" onClick={nextStep} >OK</a>
														<span>Press Enter ↵</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="form_sec step07" style={{ display: step === 7 ? 'block' : 'none' }}>
										<div className="title_sec">
											<h2><span className="number_txt">6 <i className="fas fa-arrow-right"></i></span> What's username of the email accounts?*</h2>
											
											<p>This is the <a href="mailto:USERNAME@company.com">USERNAME@company.com</a></p>
											<p>🚨 Important:</p>
											<p>Provide us with multiple usernames if you wanted 2 or 3 emails per domain.</p>
											<p>Example:<br/> If you picked 3 email accounts per domain and the name is Jessica Davis, provide 3 examples such as:</p>
											<p>jessica@, jessica.d@ and jessicadavis@</p>
										</div>
										
										<div className="inner_formsec">
											<div className="row">
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<input 
															type="text" 
															className="form-control" 
															placeholder="Type your answer here..." 
															name="email_username"
															value={email_username}
															onChange={(e) => setEmailusername(e.target.value)}
														/>
														<span className="linebreak_txt">Shift ⇧ + Enter ↵ to make a line break</span>
														{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="btn_mainsec">
														<a href="javascript:void(0);" className="btn_style" onClick={nextStep}>OK</a>
														<span>Press Enter ↵</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="form_sec step08" style={{ display: step === 8 ? 'block' : 'none' }}>
										<div className="title_sec">
											<h2><span className="number_txt">7 <i className="fas fa-arrow-right"></i></span> Would you like to forward all domains to a main domain? <span>(Recommended).*</span></h2>
											
											<p>When people type in the new domains that you will buy with us into the search bar.</p>
											<p>-> Do you want them to be forwarded to a main company website?</p>
											<p>Otherwise they wouldn't see anything.</p>
											<p>Specify your main domain to forward to.</p>
											<p>Or say "No"</p>
										</div>
										
										<div className="inner_formsec">
											<div className="row">
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="group-form">
														<input 
															type="text" 
															className="form-control" 
															placeholder="Type your answer here..." 
															name="forward_domains"
															value={forward_domains}
															onChange={(e) => setForwarddomains(e.target.value)}
														/>
														{error ? (
														  <span className="error_msg">
														    <i className="fas fa-exclamation-triangle"></i> {error}
														  </span>
														) : null}
													</div>
												</div>
												
												<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
													<div className="btn_mainsec">
														<button className="btn_style">Submit</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				
				<div className="row" style={{ display: step === 0 ? 'none' : 'block' }}>
					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<div className="next_prev_btnsec">
							<button className="btn_style next" onClick={prevStep}><i className="fas fa-chevron-up"></i></button>
							<button className="btn_style prev" onClick={nextStep}><i className="fas fa-chevron-down"></i></button>
						</div>
					</div>
				</div>
				<div className="domain_list_mainsec">					
					{domaindata.map((domain, index) => (
					  <ul key={domain.id}> {/* Assuming domain.id is unique */}
					    <li>
					      <div className="name_box">
					        <i className="fas fa-globe"></i>
					        <span>{domain.name}</span>
					      </div>
					      {domain.available ? (
					        <div className="price_btn_box">
					          <span className="price_txt">
					            ${domain.other_info[0].price + 10}/
					            {domain.other_info[0].duration} {domain.other_info[0].duration_type}
					          </span>
					          <div className="btn_mainsec"> 
						          <label className="custom_checkbox">
									  <input 
									  	type="checkbox" 
									  	data-domain={domain.name} 
									  	data-value={domain.other_info[0].price + 10}
									  	data-ind={index}
									  	value={domain.other_info[0].price + 10} 
									  	onChange={handleCheckboxChange} 
									  	/>
									  <span className="checkmark"></span>
								  </label>
					          </div>
					          
						          

					        </div>
					      ) : (
					        <div className="unavailable">
					          <span>Unavailable</span>
					        </div>
					      )}
					    </li>
					  </ul>
					))}	
					{tamout > 0 ? (<div className="payment_form">  <h3>Total Amount: ${tamout.toFixed(2)}</h3><Elements stripe={stripePromise}>
						            <CardForm handlePayment={handlePayment} />
						          </Elements> </div>) : (null) }

					{view_start_again ? <div className="row text-center" style={{marginTop: '40px'}}>
						<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="btn_mainsec">
								<button className="btn_style" onClick={start_again}>Start Again</button>
							</div>
						</div>
					</div> : null }
				</div>			
			</div>
			 {loading ? <div className="loader_mainsec">
        <div className="spinner"></div>
      </div> : <div></div>}
		</div>
	);
};

export default Searchdomain;